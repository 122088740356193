html {
  height: 100%;
  width: 100%;
  overflow: clip;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Rubik', sans-serif;
  background-image: url('./background.webp');
  background-position: center;
  background-size: cover;
}
